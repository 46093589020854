import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Logo from "../img/logo2.png";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const cat = useLocation().search.split("=")[1];
  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="links">
          <Link className="link" to="/?cat=art">
            <h6 className={`${cat === "art" ? "active" : ""}`}>ART</h6>
          </Link>
          <Link className="link" to="/?cat=science">
            <h6 className={`${cat === "science" ? "active" : ""}`}>SCIENCE</h6>
          </Link>
          <Link className="link" to="/?cat=technology">
            <h6 className={`${cat === "technology" ? "active" : ""}`}>
              TECHNOLOGIE
            </h6>
          </Link>
          <Link className="link" to="/?cat=cinema">
            <h6 className={`${cat === "cinema" ? "active" : ""}`}>CINEMA</h6>
          </Link>
          <Link className="link" to="/?cat=design">
            <h6 className={`${cat === "design" ? "active" : ""}`}>DESIGN</h6>
          </Link>
          <Link className="link" to="/?cat=food">
            <h6 className={`${cat === "food" ? "active" : ""}`}>NOURRITURE</h6>
          </Link>
          <span>{currentUser?.username}</span>
          {currentUser ? (
            <span onClick={logout}>Déconnexion</span>
          ) : (
            <Link className="link" to="/login">
              Connexion
            </Link>
          )}
          <span className="write">
            <Link className="link" to="/write">
              Pub
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
